$brand-palette: (
    // Primary Colours
    black: #000000,
    white: #ffffff,

    // Secondary Colours
    neutral: #f2eded,
    green: #7aa690,
    dark-green: #487f66,

    // Highlight Colours
    sales: #fd2969,
    promo-old: #00ad06,
    promo: #4d824e,
    loyalty-old: #4766ff,
    loyalty: #a76287,
    background-promo-old: #0dba13,
    background-promo: #6db06f,
    background-black: #171515,
    background-gray: #474545,
    card-black: #232020,

    // Seasonal Colours
    summer-yellow: #ffcb42,
    summer-lilac: #bc6ee1,
    summer-sky: #8cdbdb,
    winter-red: #bf1212,
    winter-prune: #57173f,
    winter-powder-blue: #789cb9,
    xmas-red: #bA0101,

    // System Colours
    alert: #ee0000,
    success: #038728,
    warning: #ffa12e,
    success-notification-old: #048127,
    success-notification: #07852b,
    outrageous-orange-old: #ff5923,
    outrageous-orange: #da7b5c,
    error-red: #fd2b2b,
    validation-green: #20c74f,

    // Notification color
    notification: #f1c5c5,
    notification-loyalty-old: #4766ff,
    notification-loyalty: #a76287,

    // Pastel
    jet-stream: #c4dbd1,
    chinese-white: #d6ede3,
    platinum: #e3e6e5,
    bright-gray: #eeeeee,
    pastel-gray: #dacdba,
    almond: #eee1cd,
    pale-chestnut: #dbb1b1,
    pale-pink: #f9dddd,

    //
    dark-gray: #646464,
    light-gray: #bebebe,
    lighter-gray: #f9f9f9,

    // Other Colours
    border-gray: #000000,
    light-salmon-pink: #ff9999,
    orange-red: #e6453c,
    light-orange: #f67a1a,
    medium-light-yellow: #f4db89,

    // Loyalty and shipping bar
    loyalty-bar: #ffffff40,
    shipping-bar: #e9e9e9,

    // Old Colours
    medium-gray: #999999,
    secondary: #364c60,
    summer: #ffcb42,
    blu-banner: #c9ceec
);

$foundation-palette: (
    primary: #000000,
    secondary: #85715d,
    success: #038728,
    warning: #ffa12e,
    alert: #ee0000
);

$buttons-palette-black-text: (
    primary: map-get($brand-palette, white),
    christmas: map-get($brand-palette, christmas),
    secondary: map-get($brand-palette, light-gray)
);

$buttons-palette-white-text: (
    black: map-get($brand-palette, black)
);

@function color($color) {
    @return map-get($brand-palette, $color);
}

/*
 * Gray Colors
*/

$grays-palette: (
    1: #000000,
    2: #646464,
    3: #bebebe,
    4: #f8f8f8,
    5: #cccccc,
    6: #e6e6e6,
    7: #f5f5f5,
    8: #f9f9f9,
    9: #b2b2b0,
    10: #828282,
    11: #f7f7f7,
    12: #4d4d4d,
    13: #262626,
    14: #e5e5e5
);

// Create grays mixin to read from map
@function gray($number) {
    @return map-get($grays-palette, $number);
}

// Generate color variables from map
$black: color(black);
$white: color(white);
$neutral: color(neutral);
$green: color(green);
$dark-green: color(dark-green);
$sales: color(sales);
$promo-old: color(promo-old);
$promo: color(promo);
$loyalty-old: color(loyalty-old);
$loyalty: color(loyalty);
$background-promo-old: color(background-promo-old);
$background-promo: color(background-promo);
$background-black: color(background-black);
$background-gray: color(background-gray);
$card-black: color(card-black);
$primary: color(black);
$primary-color: color(black);
$secondary: color(summer);
$summer: color(summer);
$summer-yellow: color(summer-yellow);
$summer-lilac: color(summer-lilac);
$summer-sky: color(summer-sky);
$winter-red: color(winter-red);
$winter-prune: color(winter-prune);
$winter-powder-blue: color(winter-powder-blue);
$xmas-red: color(xmas-red);
$alert: color(alert);
$success: color(success);
$warning: color(warning);
$success-notification-old: color(success-notification-old);
$success-notification: color(success-notification);
$outrageous-orange-old: color(outrageous-orange-old);
$outrageous-orange: color(outrageous-orange);
$error-red: color(error-red);
$validation-green: color(validation-green);
$notification: color(notification);
$notification-loyalty-old: color(notification-loyalty-old);
$notification-loyalty: color(notification-loyalty);
$jet-stream: color(jet-stream);
$chinese-white: color(chinese-white);
$platinum: color(platinum);
$bright-gray: color(bright-gray);
$pastel-gray: color(pastel-gray);
$almond: color(almond);
$pale-chestnut: color(pale-chestnut);
$pale-pink: color(pale-pink);
$dark-gray: color(dark-gray);
$border-gray: color(border-gray);
$light-gray: color(light-gray);
$lighter-gray: color(lighter-gray);

$medium-gray: color(medium-gray);
$blu-banner: color(blu-banner);
$light-salmon-pink: color(light-salmon-pink);
$orange-red: color(orange-red);
$light-orange: color(light-orange);
$medium-light-yellow: color(medium-light-yellow);

$loyalty-bar: color(loyalty-bar);
$shipping-bar: color(shipping-bar);

// ADD FROM INT DA RIMUOVERE
$gold: color(info);
$light-more-gray: #eeeeee;
$more-light-gray: color(light-more-gray);
$brand-green: color(light-more-gray);
$alert-light: color(light-more-gray);
$medium-light-gray: color(light-more-gray);
$brand-blue: color(light-more-gray);
$border-table-gray: color(light-more-gray);
$light-over-gray: color(light-more-gray);
$medium-dark-gray: color(light-more-gray);
$bg-bra-gray: color(light-more-gray);
$gray-yellow: color(light-more-gray);
$medium-more-gray: color(light-more-gray);
$gray-balance: color(light-more-gray);
///////////////////////////////

// Create gray classe
@each $number, $hex in $grays-palette {
    .color-gray-#{$number} {
        color: $hex !important;
    }

    .bg-gray-#{$number} {
        background-color: $hex !important;
    }
}

/// Generate font-color & bg-color class helpers
///
/// @param {Map} $palette - colors map
@mixin extend-palette($palette) {
    @each $color, $hex in $palette {
        .color-#{$color} {
            color: $hex !important;
        }

        .bg-#{$color} {
            background-color: $hex !important;
        }
    }
}

.transparent {
    opacity: 0.5;
}
