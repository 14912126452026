$include-fonts: false;
@import "../setup/settings";

.main-header {
    nav {
        &.register-navigation {
            //border-bottom: 1px solid $border-gray;
        }
    }
}
.page[data-action='Login-Show'] {
    .already-registered-1 {
        padding: 0;
        background: unset;
        margin: rem-calc(40 0);
        font-weight: 400;
        font-size: rem-calc(14);
        color: #EE0000;
        a {
            color: #EE0000;
        }
    }
}

.ico-back-registration {
    vertical-align: -webkit-baseline-middle;
    .ico-back {
        width: rem-calc(20);
        height: rem-calc(14);
    }
}
.link-loyalty-login{
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    font-weight: 600;
}

.login-cell-image, .register-cell-image {
    object-fit: cover;
    position: fixed;
    top: rem-calc(71);
    left: 0;
    width: 50%;
}

.login-forms-cell {
    @include breakpoint (large up){
        max-width: rem-calc(423);
        margin: 0 auto;
    }

    .login-tabs {
        ul {
            &.tabs {
                width: 100%;
                margin: rem-calc(50 0 0 0);
                text-align: center;
                @include breakpoint (large up){
                    margin-top: rem-calc(40);
                }
                @include breakpoint (medium down){
                    margin-top: 0;
                }
                li{
                    font-size: rem-calc(14);
                    font-weight: 600;
                    line-height: rem-calc(20);
                    margin: rem-calc(0 2.5);
                    width: 47%;
                    border-bottom: 1px solid $border-gray;
                    padding: rem-calc(16 10 16 10);
                    &.current {
                        background:none;
                        color: $black;
                        border-bottom: rem-calc(2) solid $black;
                    }
                }
            }

            &.tabs-fix-overflow {
                max-width: rem-calc(430);

                @include breakpoint (small down){
                    max-width: rem-calc(355);
                    li, li.current {
                        font-size: rem-calc(12);
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .login-title-question, .register-title-question {
        font-weight: 400;
        font-size: rem-calc(20);
        line-height: rem-calc(28);
        letter-spacing: rem-calc(0.2);
        margin-bottom: rem-calc(40);
        text-transform: none;
        @include breakpoint(medium down) {
            padding: rem-calc(0 16);
        }
    }
    p{
        // @include breakpoint(medium down) {
        //     padding: rem-calc(0 16);
        // }
    }
    .login-form-container,
    .registration-boxes-container,
    .register-form-container {
        .login-register-gigya {
            text-align: center;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
        }
        @include breakpoint (medium down){
            padding: rem-calc(0 16);
        }
        .hide-email-only{
            margin-bottom: rem-calc(24);
            @include breakpoint (medium down){
                margin-bottom: rem-calc(16);
            }
        }
        input, input:-internal-autofill-selected {
            background-color: $white!important;
        }
    }
    .login-text{
        margin: rem-calc(24 0 16);
    }
    .password-reset{
        display: flex;
        margin-bottom: rem-calc(43);
        @include breakpoint (medium down){
            margin-bottom: 24px;
        }
    }
    .login-forgot-password-save-access {
        .login-save-access {
            vertical-align: bottom;
            margin-bottom: rem-calc(35);
            @include breakpoint (medium down){
                margin-bottom: 14px;
            }
            &.abandoned {
                margin-top: rem-calc(32);
            }

            .checkbox-label {
                font-size: rem-calc(10);
                line-height: rem-calc(18);
                text-transform: uppercase;
                &.abandoned {
                    padding-left: rem-calc(8);
                    vertical-align: super;
                }
            }
            .social-connections-icon{
                padding-left: rem-calc(10);
            }
        }
    }

    .info-rememberme{
        color: black!important;
        filter: drop-shadow(1px 2px 3px #ccc);
        @include breakpoint (medium down){
            left: rem-calc(-80);
            max-width: rem-calc(280);
        }
    }
    .social-network-login-container {
        p {
            margin-bottom: rem-calc(22);
        }
        .social-network-login-symbols {
            text-align: center;
            margin-bottom: rem-calc(36);
            a {
                margin: rem-calc(0 12);
                font-size: rem-calc(23);
            }
        }

        &.border-top {
            border-top: 1px solid $border-gray;
            padding-top: rem-calc(13);
        }
    }
    .registration-boxes-container {
        .myintimissimi-card-registration-box,
        .new-registration-login-box {
            padding-top: rem-calc(24);
            margin-bottom: rem-calc(24);
        }
        h5{
            font-size: rem-calc(20);
            line-height: rem-calc(29);
            margin-bottom: rem-calc(24);
            font-weight: 400;
            text-align: left;
            text-transform: none;
        }
        p{
            font-size: rem-calc(14);
        }
        .button{
            display: inline-flex;
            padding: rem-calc(10);
            line-height: rem-calc(20);
            @include breakpoint (medium down){
                padding: rem-calc(4);
                line-height: rem-calc(16);
            }
        }
        .loyalty.button {
            padding: rem-calc(5);
        }
        .loyalty.fix-margin-grid{
            margin: rem-calc(0 -6);
            .fix-margin-cell{
                margin: rem-calc(0 6);
                width: calc(100% - 12px);
                &.label-input-loyalty-pin{
                    width: calc(50% - 12px);
                }
                &.label-input-loyalty-code{
                    width: calc(50% - 12px);
                }
                button{
                    margin-top: rem-calc(9);
                    line-height: rem-calc(20);
                }
            }
        }
    }
}
.button-login{
    margin-top: rem-calc(10);
}
.button-register-margin-40, .button-login-margin-40{
    margin-bottom: rem-calc(40);
}

#tab-login-cell, #tab-registration-cell {
    // p {
    //     @include breakpoint(medium down) {
    //         padding-left: rem-calc(16);
    //         padding-right: rem-calc(16);
    //     }
    // }
    @include breakpoint(medium down) {
        padding-top: rem-calc(20);
    }
    padding-top: rem-calc(40);
    .gigyaLoginCell{
        margin-bottom: rem-calc(24);
    }
    .social-login-form-error{
        margin-bottom: rem-calc(16);
    }
}
.label-input-loyalty-code {
    width: 76%;
    display: inline-block;
}
.label-input-loyalty-pin {
    width: 20%;
    display: inline-block;
    float: right;
}

.success-block {
    padding: rem-calc(20 12);
    background: #F5F5F5;
}
html[lang="hr-HR"] {
    .page[data-action='Login-Show'] {
        .conversion-rate-content {
            z-index: 1;
        }
    }
}
// .........registration page..........

.registration-page-title {
    text-transform: none;
    font-size: rem-calc(40);
    line-height: rem-calc(35);
    font-weight: 400;
    @include breakpoint (large up){
        margin-bottom: rem-calc(8);
        margin-top: rem-calc(65);
    }
    @include breakpoint (medium down){
        margin: rem-calc(24 0 13 0);
        font-size: rem-calc(20);
        line-height: rem-calc(26);
    }
    &.first {
        font-size: rem-calc(40);
        font-weight: 400;
        line-height: rem-calc(35);
        @include breakpoint(medium down){
            font-size: rem-calc(20);
            line-height: rem-calc(26);
        }
    }
}
.reg-phone-width {
    max-width: rem-calc(423);
    margin: 0 auto;
}
.reg-form-width {
    max-width: rem-calc(1040);
}
.login-paragraph{
    font-size: 14px!important;
}


.phone-number-registration-field {
    padding-left: rem-calc(5);
    &.right {
        padding-right:rem-calc(5);
        padding-left: 0;
    }
}

.steps-progress-bar-registration {
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(43);
    @include breakpoint (large up){
        margin-bottom: rem-calc(40);
    }
    margin-top: rem-calc(40);

    .circle-black {
        margin-right: rem-calc(24);
        height: rem-calc(20);
        width: rem-calc(20);
        background-color: $black;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        position: relative;
        &-text {
            color: white;
            font-weight: 600;
            font-size: rem-calc(12);
            line-height: rem-calc(20);

        }
        &.inactive {
            &-gray{
                background-color: #BEBEBE;
            }
            background-color: $border-gray;
            position: relative;
            &:after {
                content:'';
                width: rem-calc(4);
                height: rem-calc(4);
                border-radius: 50%;
                background: $black;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: rem-calc(-2 0 0 -2);
            }
        }
    }
    .horizontal-row-step {
        height: rem-calc(20);
        width: rem-calc(25);
        margin: rem-calc(0 5);
        hr {
            margin: 40% 0;
            border-bottom: 1px solid $black;
        }
        &.gray {
            hr {
                border-bottom: 1px solid $border-gray;
            }
        }
    }
}
.final-title-register {
    margin-bottom: rem-calc(40);
}

.register-form-final-padding-right {
    @include breakpoint (large up){
        padding-right: rem-calc(24);
    }
}
.email-register {
    padding-bottom: rem-calc(8);
    padding-top: rem-calc(37);
}
.text-register-privacy-policy {
    display: inline-block;
    font-size: rem-calc(14);
    &.loyalty-condition {
        display: block;
        margin-bottom: rem-calc(16);
    }
}
.text-register-condition-sales {
    color: #262626;
    font-size: rem-calc(14);
    padding-top: rem-calc(24);
    margin-top: rem-calc(40);
    border-top: 1px solid #E6E6E6;
    &.already-loyalty{
        margin-top: rem-calc(-5);
    }
    @include breakpoint (medium down){
        font-size: rem-calc(12);
    }
}
.policy-register-form {
    @include breakpoint (large up){
        margin-top: rem-calc(40);
    }
    border-top: rem-calc(1) solid #F2EDED;
    margin-top: rem-calc(24);
    @include breakpoint(medium only) {
        margin-top: rem-calc(40);
    }
    .fix-padding {
        padding-top: rem-calc(40);
    }
    .preferences-privacy-prefilled{
        margin-bottom: rem-calc(32);
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        @include breakpoint (medium down){
            margin-bottom: rem-calc(24);
        }
    }
}
.spacer-register-20{
    height: rem-calc(20);
    @include breakpoint(medium down) {
        height: rem-calc(32);
    }
}
.mobile-flex-fields {
    @include breakpoint (medium down){
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

.register-form-divider {
    border-bottom: 1px solid $border-gray;
    width: 100%;
}

.my-card-register {
    font-size: rem-calc(18);
    margin: rem-calc(25 0);
    letter-spacing: rem-calc(0.5);
    @include breakpoint (medium down){
        margin:rem-calc(20 0);
    }
}
.button-register-final {
    margin-top: rem-calc(33);
    @include breakpoint (medium down){
        width: 100%;
        margin-bottom: 0;
    }
}

.link-privacy-policy-condition {
    &.disabled {
        border: 0;
    }
}
.login-datepicker {
    position: relative;
    margin-bottom: rem-calc(16);
    img {
        position: absolute;
        right: rem-calc(5);
        bottom: rem-calc(8);
    }
}

.text-color-light-gray {
    color: #828282;
    &.link-gray {
        border-bottom: 1px solid #828282;
    }
    &.label-gray {
        &.checkbox-label {
            // color: #828282!important;
        }
    }
}
#reg-phone-error {
    margin-bottom: rem-calc(40);
}
.opposition-field-my-intimissimi {
    padding-right: rem-calc(10);

}
.opposition-field-login-date {
    padding-right: rem-calc(10);
    .info-icon{
        top: rem-calc(4);
        left:   rem-calc(5);
    }
}
.registration-page-form-block {
    &.email-only-register {
        width: rem-calc(340);
        .hide-email-only {
            display: none;
        }
    }
}

.big-title-lover-card {
    h5{
        font-size: rem-calc(20);
        line-height: rem-calc(28);
        letter-spacing: rem-calc(1);
    }
}

.password-field-with-tooltip {
    position: relative;
    width: 100%;
    i {
        position: absolute;
        top: 50%;
        right: rem-calc(35);
        .tooltip {
            right: 0;
            left: inherit;
        }
    }
}

.modal-opposition {
    .title-modal-opposition {
        margin-bottom: rem-calc(32);
        font-size: rem-calc(20);
    }
}

// .......errori.......

.login-form-error-block {
    color: #EE0000;
    background: transparent !important;
    font-size: rem-calc(14);
    padding: 0 !important;
    font-weight: 400;
}

.tingle-modal-box {
    button {
        &.close-button-info {
            margin-right: 0;
            position: absolute;
            top: rem-calc(20);
            right: rem-calc(20);
            img {
                width: rem-calc(14);
            }
        }
    }
}

.card-reset-password {
    max-width: rem-calc(340);
    margin: 0 auto;
}

.info-tooltip-reset-password {
    position: absolute;
    top: 39%;
    right: rem-calc(4);
    .tooltip {
        @include breakpoint (medium down){
            left: inherit;
            right: rem-calc(-6);
        }
    }
}

.dob {
    position: relative;
    &.cell {
        margin-top: rem-calc(32);
        @include breakpoint (medium down) {
            margin-top: 0;
        }
    }
    .js-modal-tooltip-date {
        position: absolute;
        top: 35%;
        right: 0;
    }
}
.datepicker {
    &.dropdown-menu {
        border-radius: 0;
        border: rem-calc(1) solid black;
        width: 22%;
        @include breakpoint (medium down) {
            width: 80%;
        }
    }
    th {
        font-size: rem-calc(14);
        font-weight: 600;
    }
    td {
        font-size: rem-calc(14);
        &.old, &.new {
            color: #BEBEBE;
        }
    }
    table {
        width: 100%;
    }
}

.page-title-new-password{
    font-size: rem-calc(24);
    margin: rem-calc(16 0);
    @include breakpoint (medium down){
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        margin: rem-calc(24 0 16);
    }
}

@media only screen and (max-width: 400px){
    .login-forgot-password-save-access {
        .login-save-access {
            vertical-align: bottom;
            padding-left: 12px;

            .checkbox-label {
                font-size: 10px !important;
                line-height: rem-calc(24);
                text-transform: uppercase;
            }
            .social-connections-icon{
                padding-left: rem-calc(10);
            }
        }
    }
}

.registration-phone-confirm-block {
    .sendNewOtp {
        font-weight: $global-weight-dark;
    }
    .phoneConfirmCodeBox {
        justify-content: center;
    }
}
.registration-step-final {
    .radio-label{
        font-size: rem-calc(16) !important;
    }
    .prefilledCustomerData {
        pointer-events: none;
        background: unset;
    }
    .text-loyalty{
        color: #8D9CD0 !important;
    }
    .policy-promotion-loyalty{
        padding-top: rem-calc(40);
        border-top: rem-calc(1) solid #F2EDED;
    }
    .fix-icon-margin {
        @include breakpoint (medium down) {
            margin-top: rem-calc(40);
        }
    }
    .input-grid{
        input{
            margin-bottom: rem-calc(16);
        }
        label.boolean-field{
            margin-bottom: rem-calc(24);
        }
    }
    .margin-privacy{
        @include breakpoint (medium down){
            margin-top: 0;
        }
    }
    .large-icon-width{
        @include breakpoint(large up){
            width: auto;
            margin-right: rem-calc(16);
        }
        @include breakpoint (medium down){
            text-align: center;
        }
        img{
            width: rem-calc(55);
        }
    }
    .my-login-card-register{
        font-size: rem-calc(16);
        line-height: rem-calc(22);
        margin-bottom: rem-calc(16);
        color: $loyalty;
        @include breakpoint (medium down){
            margin-top: 0;
        }
    }
    .advantages-subtitle{
        margin-bottom: rem-calc(24);
        line-height: rem-calc(22);
        @include breakpoint(medium down){
            margin-bottom: rem-calc(40);
        }
    }
    .loyalty-advantages-cell{
        font-size: rem-calc(16);
        .conditions-register{
            font-size: rem-calc(14) !important;
            @include breakpoint(medium down){
                font-size: rem-calc(14) !important;
            }
        }
    }
    .login-form-error-block {
        margin-bottom: rem-calc(40);
    }
    .mobile-flex-fields{
        position: relative;
        /*
        .pr-0{
            @include breakpoint(medium down){
                padding-right: 0;
            }
        }*/
        &.loyalty-flag-cell{
            @include breakpoint(medium down){
                padding-right: rem-calc(16);
            }
        }
        .popover-loyalty-member-clz{
            width: rem-calc(184);
            position: absolute;
            transform: translateY(-92%);
            top: -100%;
            padding: rem-calc(8);
            right: rem-calc(-55);
            font-size: rem-calc(10);
            line-height: rem-calc(18);
            border: rem-calc(1) solid #000000;
            border-radius: 5px;
            background-color: #FFFFFF;
            @include breakpoint(medium down){
                right: 0;
                left: unset;
            }
            // &:after, &:before {
            //     bottom: rem-calc(-12);
            //     border: solid transparent;
            //     content: " ";
            //     height: 0;
            //     width: 0;
            //     position: absolute;
            //     pointer-events: none;
            //     border-left: 5px solid transparent;
            //     border-right: 5px solid transparent;
            //     border-top: 10px solid #F8F2F0;
            //     @include breakpoint(medium down){
            //         right: rem-calc(15) !important;
            //     }
            // }
            // &:after {
            //     right: rem-calc(114);
            //     margin-left: rem-calc(-10);
            // }
            // &:before {
            //     right: rem-calc(114);
            //     margin-left: rem-calc(-11);
            // }
            .tooltip-title{
                margin-bottom: rem-calc(8);
                font-size: rem-calc(10);
                line-height: rem-calc(18);
            }
        }
        /* The switch - the box around the slider */
        .switch {
            position: relative;
            display: inline-block;
            width: rem-calc(47);
            height: rem-calc(28);
        }
        /* Hide default HTML checkbox */
        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }
        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #CFCECF;
            -webkit-transition: .4s;
            transition: .4s;
            width: rem-calc(48);
            height: rem-calc(24);
            @include breakpoint (medium down) {
                width: rem-calc(80);
                height: rem-calc(40);
            }
        }
        .slider:before {
            position: absolute;
            content: "";
            height: rem-calc(16);
            width: rem-calc(16);
            left: rem-calc(4);
            top: rem-calc(4);
            background-color: #FFFFFF;
            box-shadow: 2px 3px 6px rgba($color: #000000, $alpha: 0.16);
            transition: .4s;
            border-radius: 50%;
            @include breakpoint (medium down) {
                height: rem-calc(30);
                width: rem-calc(30);
                left: rem-calc(5);
                top: rem-calc(5);
            }
        }
        input:checked + .slider:before {
            transform: translateX(rem-calc(24));
            @include breakpoint (medium down) {
                transform: translateX(rem-calc(40));
            }
        }
        input:checked + .slider{
            background-color: #010001;
        }
        .slider.round {
            border-radius: 34px;
        }
        .slider.loyalty-highlight{
            z-index: 0;
            &::after {
                position: absolute;
                content: "";
                height: rem-calc(36);
                width: rem-calc(36);
                left: rem-calc(-7);
                top: rem-calc(-5);
                border-radius: 50%;
                background-color:rgba($color: $loyalty, $alpha: 0.7);
                z-index: -1;
                animation: pulse-ring 1.25s cubic-bezier(0.2, 0.5, 0.3, 1) infinite;
                opacity: 0.6;
                @include breakpoint (medium down) {
                    height: rem-calc(65);
                    width: rem-calc(65);
                    left: rem-calc(-12);
                    top: rem-calc(-12);
                }
            }
        }
        @keyframes pulse-ring {
            0% {
                transform: scale(.5);
            }
            80%, 100% {
                opacity: 0.6;
            }
        }
    }
}

.landing-page-Welcome-Series{
    @include breakpoint (medium down){
        padding: 0;
    }
    .welcome-image-cell{
        img{
            width: 100%;
        }
        position: relative;
        .welcome-text-box{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -65%);
            text-align: center;
            width: 237px;
            font-size: rem-calc(20);
            @include breakpoint (medium down){
                transform: translate(-50%, -50%);
            }
            span{
                display: block;
            }
            .welcome-title{
                margin-bottom: rem-calc(20);
                font-size: rem-calc(20);
                font-weight: 500;
                @include breakpoint (medium down){
                    font-size: rem-calc(20);
                }
            }
            .welcome-description{
                font-size: rem-calc(16);
                .points{
                    color: #8D9CD0;
                    font-weight: 500;
                    display: inline-block;
                }
            }
        }
    }
    .welcome-login-cell{
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        padding: rem-calc(50 35 20);
        @include breakpoint (medium down){
            padding: rem-calc(40 50);
        }
        .text-box-login{
            @include breakpoint (large up){
                width: 70%;
                margin: 0 auto;
            }
            .welcome-title{
                display: block;
                font-size: rem-calc(20);
                margin-bottom: rem-calc(20);
                font-weight: 500;
                text-align: center;
                @include breakpoint (medium down){
                    font-size: rem-calc(24);
                    margin-bottom: rem-calc(20);
                }
            }
            .welcome-desc{
                display: block;
                font-size: rem-calc(14);
                font-weight: 400;
                margin-bottom: rem-calc(30);
                text-align: center;
            }
        }
        .content-box-login{
            margin-top: rem-calc(30);
            @include breakpoint (medium down){
                margin-top: rem-calc(0);
            }
            .welcome-options-title{
                font-size: rem-calc(16);
                font-weight: 400;
                display: block;
                margin-bottom: rem-calc(17);
                @include breakpoint (medium down){
                    margin-bottom: rem-calc(17);
                }
            }
            .welcome-options{
                display: flex;
                align-items: center;
                font-weight: 400;
                margin-bottom: rem-calc(20);
                img{
                    width: 20px;
                    margin-right: rem-calc(16);
                    @include breakpoint (medium down){
                        margin-top: rem-calc(4);
                    }
                }
            }
        }
    }
}

html[lang="ru-UA"], html[lang="uk-UA"]{
    #loyalty-form-cardPin{
        padding-left: 0;
        padding-right: 0;
    }
}
.card-title {
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(8);
}

.text-end-container .errors-list {
    text-align-last: end;
}

// properties for fixing the footer behaviour

.page {
    &.page--login{
        @include breakpoint (large up){
            margin-bottom: rem-calc(69);
            .footer-checkout{
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
    &[data-action='Account-RegistrationFormShow']{
        .footer-bottom-checkout{
            margin-top: 0 !important;
        }
    }
}
