@font-face {
    font-family: 'TwCent';
    src: url('../fonts/TwCenMTStd.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'TwCent';
    src: url('../fonts/TwCenMTStd-Bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}